import {
  useRefresh,
  List,
  Datagrid,
  FunctionField,
  SearchInput,
  useRecordContext,
  Button,
  useDataProvider,
  TopToolbar,
  FilterForm,
} from "react-admin"
//import { List, Datagrid, FunctionField, SearchInput, useRecordContext, Button } from "react-admin";
import { SocialCodeToIcon } from "../data"
import { chain } from "lodash"
import { RowButton } from "../shared/components/RowButton"
import { ENABLE_DISABLE_KOL_PROFILE } from "../queries/kol-profile"
import React, { useEffect, useState } from "react"
import * as Dialog from "@radix-ui/react-dialog"
import styled from "styled-components"
import { centsToUsd } from "../utils/format"
import { useMutation } from "@apollo/client"
import { useRole } from "../hooks/useRole"
import {
  Stack,
  Button as MuiButton,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TablePagination,
  Container,
  TextField
} from "@mui/material"
import { ReactComponent as IconExport } from "../assets/ic-export.svg"
import { Link } from "react-router-dom"
import EditBalancePopup from "./EditBalancePopup"
import { useQuery } from 'react-query';

const BankVerificationStatusMapping = {
  PENDING: {
    text: "Waiting for verification",
    color: "#E4A400",
  },
  VERIFIED: {
    text: "Verified",
    color: "#00AD31",
  },
  REJECTED: {
    text: "Rejected",
    color: "#FF0000",
  },
  INITIAL: {
    text: "N/A",
    color: "#E4A400",
  },
}

const getBankStatus = (bank_verification_status: string) => {
  if (bank_verification_status in BankVerificationStatusMapping) {
    return BankVerificationStatusMapping[bank_verification_status as keyof typeof BankVerificationStatusMapping]
  }

  return BankVerificationStatusMapping.INITIAL
}

const getKolProfiles = async (dataProvider: any, nameOrEmail: string) => {
  const response = await dataProvider.getList("kol_profile", {
    pagination: { page: 1, perPage: 1000 },
    sort: { field: "id", order: "ASC" },
    filter: {},
    custom_filter: {
      'query': nameOrEmail
    },
  })
  return response
}



const useGetKolProfile = (dataProvider: any, nameOrEmail: string) =>
  useQuery({
    queryKey: [`getkol-profile`],
    queryFn: async () => {
      return (await getKolProfiles(dataProvider, nameOrEmail));
    },
  });

export const KolProfilesList = (props: any) => {
  const dataProvider = useDataProvider()

  const [nameEmailFilter, setNameEmailFilter] = useState(localStorage.getItem('kol_query') || '');
  const [inputValue, setInputValue] = useState(localStorage.getItem('kol_query') || '');
  const debouncedInputValue = useDebounce(inputValue, 500);

  const { data: response, error, isLoading, refetch } = useGetKolProfile(dataProvider, nameEmailFilter);
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const totalCount = response?.total || 0
  const kolProfiles = response?.data.slice(page * rowsPerPage, (page + 1) * rowsPerPage) || []
  const [editProfile, setEditProfile] = useState<any>(undefined)


  const refresh = useRefresh()
  const [enableDisableKolProfile, enableDisableKolProfileResult] = useMutation<
    any,
    {
      isApproved: boolean
      isActive: boolean
      kolProfileId: string
    }
  >(ENABLE_DISABLE_KOL_PROFILE)

  if (enableDisableKolProfileResult.called && !enableDisableKolProfileResult.loading) {
    refetch()
  }

  const role = useRole()

  const handleExport = async () => {
    const headerData = ["No", "Full Name", "Platforms", "Payment Info", "Profile Status", "Current Balance", "Last Payout"]
    const dataArray = (response?.data ?? []).map((item: any, index: number) => {
      const socialPlatforms = (item?.social_platforms || [])
        .filter((platform: any) => platform?.platform_username !== undefined && platform?.platform_username !== "")
        .map((platform: any) => platform?.social_platform_code)
        .join(", ")
      const { text: bankStatus, color } = getBankStatus(item?.bank_verification_status)

      const lastPayout = item.payouts.length == 0 ? "" : new Date(Date.parse(item.payouts[0].timestamp)).toISOString()

      return [
        `"${index + 1}"`,
        `"${item?.first_name || ""}  ${item?.last_name || ""}"`,
        `"${socialPlatforms}"`,
        `"${bankStatus}"`,
        `"${item.is_approved && item.is_active ? "Active" : "Inactive"}"`,
        `"${centsToUsd(item?.wallet?.amount_usd_cents)}"`,
        `"${lastPayout}"`,
      ].join(",")
    })
    const csvString = [headerData.join(","), ...dataArray].join("\n")
    const blob = new Blob([csvString], { type: "text/csv" })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = "kol_profile.csv"

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    refetch();
  }, [nameEmailFilter]);

  const handleFilterChange = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
  };

  useEffect(() => {
    if (debouncedInputValue) {
      setNameEmailFilter(debouncedInputValue);
    } else {
      setNameEmailFilter('')
    }
    localStorage.setItem('kol_query', debouncedInputValue)
  }, [debouncedInputValue]);

  return (
    <Container maxWidth={false} sx={{ width: "100%", maxWidth: "none", padding: "0px", margin: "0px" }}>
      <Stack spacing={"10px"} padding={"0px"} margin={"0px"}>
        <Stack alignItems={"end"} direction={"row-reverse"} style={{ backgroundColor: 'transparent' }} paddingTop={"24px"}>
          <MuiButton
            startIcon={<IconExport />}
            onClick={handleExport}
            sx={{
              color: "#6750A4",
              fontSize: "14px",
              fontWeight: "500",
              fontStyle: "normal",
              lineHeight: "20px",
              padding: "10px 12px",
            }}
          >
            EXPORT
          </MuiButton>
          <TextField
            label="Filter by Email or KOL Name"
            variant="outlined"
            value={inputValue}
            onChange={handleFilterChange}
            style={{ width: "300px" }}
          />

        </Stack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Platforms</TableCell>
                <TableCell>Payment info</TableCell>
                <TableCell>Profile Status</TableCell>
                <TableCell>Current Balance</TableCell>
                <TableCell>Last Payout</TableCell>
                <TableCell>Edit Balance</TableCell>
                <TableCell>Actions</TableCell>
                {["admin", "campaign_manager"].includes(role as string) && <TableCell>Activation</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {kolProfiles.map((kolProfile: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{`${kolProfile?.first_name || ""} ${kolProfile?.last_name || ""}`}</TableCell>
                  <TableCell>
                    <span>
                      {chain(kolProfile?.social_platforms || [])
                        .filter(
                          (platform: any) => platform?.platform_username !== undefined && platform?.platform_username !== "",
                        )
                        .map((platform: any) => platform?.social_platform_code)
                        .uniq()
                        .value()
                        .map((x: keyof typeof SocialCodeToIcon) => (
                          <picture key={x}>
                            <source
                              srcSet={`
                            /images/${SocialCodeToIcon[x]?.id}.png 1x,
                            /images/${SocialCodeToIcon[x]?.id}@2x.png 2x,
                            /images/${SocialCodeToIcon[x]?.id}@3x.png 3x
                        `}
                              type="image/png"
                            />
                            <img src={`/images/${SocialCodeToIcon[x]?.id}.png`} alt={SocialCodeToIcon[x].alt} />
                          </picture>
                        ))}
                    </span>
                  </TableCell>
                  <TableCell>
                    {(() => {
                      const { text, color } = getBankStatus(kolProfile?.bank_verification_status)
                      return <span style={{ color }}>{text}</span>
                    })()}
                  </TableCell>
                  <TableCell>
                    {(() => {
                      const isGlobalActive = kolProfile.is_approved && kolProfile.is_active
                      const text = isGlobalActive ? "Active" : "Inactive"
                      const color = isGlobalActive ? "#00AD31" : "#E4A400"

                      return <span style={{ color }}>{text}</span>
                    })()}
                  </TableCell>
                  <TableCell>{`${centsToUsd(kolProfile?.wallet?.amount_usd_cents)}`}</TableCell>
                  <TableCell>
                    {(() => {
                      if (kolProfile.payouts.length === 0) {
                        return <span></span>
                      } else {
                        const date = new Date(Date.parse(kolProfile?.payouts[0].timestamp))
                        return (
                          <span>
                            {time_ago(Date.parse(kolProfile?.payouts[0].timestamp))}
                            <br />
                            {1 + date.getMonth()}/{date.getDate()}/{date.getFullYear()}
                          </span>
                        )
                      }
                    })()}
                  </TableCell>
                  <TableCell>
                    {(() => {
                      if (role === "accountant") {
                        return (
                          <MuiButton variant="contained" onClick={() => setEditProfile(kolProfile)}>
                            Edit
                          </MuiButton>
                        )
                      }
                    })()}
                  </TableCell>
                  <TableCell>
                    {(() => {
                      return (
                        <Link to={`/kol_profile/${kolProfile.id}`}>
                          <MuiButton variant="outlined" onClick={() => setEditProfile(kolProfile)}>
                            View
                          </MuiButton>
                        </Link>
                      )
                    })()}
                  </TableCell>
                  {["admin", "campaign_manager"].includes(role as string) && (
                    <TableCell>
                      {(() => {
                        // Ideally should be 2 separate buttons
                        // 1. Approve (used when kol/merchant first registers)
                        // 2. Enable/Disable (used when kol/merchant is already approved)

                        // Button in current design is kinda combining both
                        // Logic now is:
                        // If kol is approved and active, it means it's globally active
                        // If it's not globally active, it means it's either not approved or not active
                        const isGlobalActive = kolProfile.is_approved && kolProfile.is_active

                        // BE design
                        // is_approved: default false (once approved, it's true and can't be changed, is_active then is used to control enable/disable)
                        // is_active: default true
                        // So after registration, kol is not approved and is active
                        // After approval, kol is approved and is active (globally active)
                        const kolProfileId = kolProfile.id
                        return (
                          <RowButton
                            label={isGlobalActive ? "DISABLE" : "ENABLE"}
                            variant={"contained"}
                            color={isGlobalActive ? "error" : "success"}
                            onClick={async (e) => {
                              // Default behavior is to redirect to detail page, so we need to prevent that
                              e.preventDefault()
                              if (isGlobalActive) {
                                // update is_active to false
                                console.info(`Disabling kolProfileId: ${kolProfileId}`)
                                enableDisableKolProfile({
                                  variables: {
                                    kolProfileId: kolProfileId,
                                    isApproved: true,
                                    isActive: false,
                                  },
                                })
                                return
                              }

                              if (!kolProfile.is_approved) {
                                // update is_approved to true
                                console.info(`Approving kolProfileId: ${kolProfileId}`)
                                enableDisableKolProfile({
                                  variables: {
                                    kolProfileId: kolProfileId,
                                    isApproved: true,
                                    isActive: true,
                                  },
                                })
                                return
                              }

                              // update is_active to true
                              console.info(`Enabling kolProfileId: ${kolProfileId}`)
                              enableDisableKolProfile({
                                variables: {
                                  kolProfileId: kolProfileId,
                                  isApproved: true,
                                  isActive: true,
                                },
                              })
                            }}
                          />
                        )
                      })()}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
      {editProfile !== undefined && (
        <EditBalancePopup
          open={editProfile !== undefined}
          profile={editProfile}
          handleEdited={() => {
            refetch()
            setEditProfile(undefined)
          }}
          handleClose={() => setEditProfile(undefined)}
          isMerchant={false}
        />
      )}
    </Container>
  )
}

const DialogOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  z-index: 1;
`

const DialogContent = styled(Dialog.Content)`
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  z-index: 2;
`

const DialogTitle = styled(Dialog.Title)`
  font-weight: 400;
`

function time_ago(time: any) {
  switch (typeof time) {
    case "number":
      break
    case "string":
      time = +new Date(time)
      break
    case "object":
      if (time.constructor === Date) time = time.getTime()
      break
    default:
      time = +new Date()
  }
  var time_formats = [
    [60, "seconds", 1], // 60
    [120, "1 minute ago", "1 minute from now"], // 60*2
    [3600, "minutes", 60], // 60*60, 60
    [7200, "1 hour ago", "1 hour from now"], // 60*60*2
    [86400, "hours", 3600], // 60*60*24, 60*60
    [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
    [604800, "days", 86400], // 60*60*24*7, 60*60*24
    [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
    [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
    [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
    [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
    [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ]
  var seconds = (+new Date() - time) / 1000,
    token = "ago",
    list_choice = 1

  if (seconds === 0) {
    return "Just now"
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds)
    token = "from now"
    list_choice = 2
  }
  var i = 0,
    format
  while ((format = time_formats[i++]))
    if (seconds < (format[0] as number)) {
      if (typeof format[2] === "string") return format[list_choice]
      else return Math.floor(seconds / format[2]) + " " + format[1] + " " + token
    }
  return time
}

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}